export const list = [
    {
        title: '📞 제주시 생활환경과',
        href: 'tel:064-728-3181',
        info: '제주시 생활환경과 (064-728-3181)'
    },
    {
        title: '📧 개발자 이메일',
        href: 'mailto:kunshup2000@gmail.com',
        info: 'kunshup2000@gmail.com'
    }
]
